/**
 * Core
 */
import React from 'react'

/**
 * Components
 */
import Footer from '../components/footer'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'



const CertifiedJudges = () => {

    useScrollTrigger('.reveal')
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Certified Judges</h1>
                </div>
            </section>

            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default CertifiedJudges